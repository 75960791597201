section#jumbotron {
	background: #f5f5f5;
	margin-top: 0px;
	padding-top: 150px;
	position:relative;
	background-image: url('/theme/img/photos/header.png');
	background-repeat: no-repeat;
	background-size:cover;
	
	.container {
		position:relative;
	}
	.caption {
		margin: 4em 0 5em;
		.title {
			color: #fff;
			text-shadow: 1px 1px #757575;
		    font-family: "Lato",sans-serif;
		    font-size: 32px;
		    font-weight: 900;
		    line-height: 56px;
		    text-transform: uppercase;
		}
		.subtitle {
			color: #fff;
			text-shadow: 1px 1px #454545;
			font-size: 24px;
		}
	    
	}
	.img {
		position: absolute;
		bottom: -27px;
		right: 50px;
	}
}
.top-bar-light {
	background: #fff;
	li {
		color: #808080;
		
		a {
			color: #808080;
		}
	}
}
.navbar-default {
	box-shadow: 0 1px 2px rgba(0,0,0,0.2) inset;
	-webkit-box-shadow: 0 1px 2px rgba(0,0,0,0.2) inset;
	-moz-box-shadow: 0 1px 2px rgba(0,0,0,0.2) inset;
	background: #8c0830; 
}
.navbar-brand img {
	margin-top: -44px;
	width: 135px;
	box-shadow: 0 1px 3px #757575;
	-webkit-box-shadow: 0 1px 3px #757575;
	border-radius: 2px;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
}

div.top-bar-light ul.list-inline li {
	line-height: 2.25em;
}

.navbar-default .navbar-toggle .icon-bar {
	background-color: #fff;
}


#featured {
	max-width:  500px;
	position:absolute;
	bottom: 0;
	right: 0;
}

@media (max-width: 480px) {
  	#featured {
  		position: relative;
		width: 250px;
		margin-left: 2em;
	}
}
@media (min-width: 480px) and (max-width: 1024px) {
  	#featured {
		width: 377px;
	}
}

#products .colored-boxed p {
	text-align: left;
	font-size: 16px;
	color: #808080;
}


